<template>
  <div class="mc-container">
    <div class="cm-header">
      <img
        style="height: 60px; width: 50px"
        src="@/assets/logo.png"
        alt="logo"
      />
      <div class="cm-text-logo">Mobilecrosspay</div>
    </div>
    <div class="cm-content">
      <div class="cm-box">
        <p class="info">
          Mobilecrosspay, facilite le paiement mobile en Afrique. Payer et
          encaisser simplement à distance en toute sécurité.
          <img class="illustration" src="@/assets/sp.svg" alt="illustration" />
        </p>
      </div>
      <div class="cm-form">
        <p class="cm-form-title">Connexion</p>
        <form action="" autocomplete="off">
          <span class="d-inline-block cm-mb-8" for="email">Email</span>
          <text-input
            required
            :errors="errors.email"
            type="email"
            mainClass="control has-icons-left"
            v-model="form.email"
          >
            <span class="icon is-small is-left">
              <i class="fas fa-envelope"></i>
            </span>
          </text-input>

          <span class="d-inline-block cm-mb-8 cm-mt-8" for="password"
            >Mot de passe</span
          >
          <text-input
            required
            autocomplete="off"
            type="password"
            :errors="errors.password"
            mainClass="control has-icons-left"
            v-model="form.password"
          >
            <span class="icon is-small is-left">
              <i class="fas fa-lock"></i>
            </span>
          </text-input>
          <p class="cm-mt-10">
            <button
              @click.prevent="authenticate"
              :disabled="loading"
              :class="
                loading
                  ? 'button is-fullwidth is-primary is-loading'
                  : 'button is-fullwidth is-primary'
              "
            >
              Se connecter
            </button>
          </p>
          <div class="cm-info-bottom">
            <div>
              <router-link style="font-size: 15px" to="/reset"
                >Mot de passe oublié ?</router-link
              >
            </div>
            <div>
              <span style="font-size: 15px">Pas encore de compte ?</span
              ><router-link style="font-size: 15px" class="link_cm" to="/singup"
                >Créer un compte</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput.vue";
import { TYPES } from "@/store/types";
const DEFAULT_FORM = {
  email: "",
  password: "",
};
export default {
  components: { TextInput },
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    loading: false,
    errors: [],
  }),
  methods: {
    //``
    authenticate() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.USER}/${TYPES.actions.AUTHENTICATE_USER}`,
          this.form
        )
        .then((res) => {
          this.loading = false;
          this.form = { ...DEFAULT_FORM };
          this.errors = [];
          if (res.profile == 2){
            this.$router.push({ name: "transactions" });
          }else{
            this.$router.push({ name: "home" });
          }
          //this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mc-container {
  background-color: #ecf7ff;
  width: 100%;
  height: 100vh;
  .cm-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 70px;
    width: 100%;
    img {
      height: 50px;
    }
    .cm-text-logo {
      margin-left: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.cm-content {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 20px;
  margin-left: 10%;
  margin-right: 10%;
}
.info {
  font-weight: bold;
  font-size: 18px;
}
.cm-form {
  background-color: #fff;
  min-height: 250px;
  max-height: 440px;
  border-radius: 5px;
  padding: 50px;
  .cm-form-title {
    text-align: center;
    color: #222f5a;
    font-weight: bold;
    font-size: 17px;
  }
}
.cm-info-bottom {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .illustration {
    display: none !important;
  }
  .info {
    font-weight: bold;
    font-size: 18px;
    text-align: center !important;
  }
  .cm-content {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;
  }
  .link_cm {
    font-size: 16px;
  }
}
</style>