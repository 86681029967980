<template>
  <div :class="mainClass">
    <label v-if="label" :for="id">{{ label }}</label>
    <input
      autocomplete="off"
      :class="errors.length ? 'is-danger' : ''"
      :id="id"
      ref="input"
      v-bind="$attrs"
      class="input"
      :type="type"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <slot></slot>
    <div v-if="errors.length" :class="errors.length ? 'invalid-feedback' : ''">
      <strong class="has-text-danger" style="font-size: 12px">{{
        errors[0]
      }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${this._uid}`;
      },
    },
    type: {
      type: String,
      default: "text",
    },
    value: String,
    label: String,
    errors: {
      type: Array,
      default: () => [],
    },
    mainClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
  },
};
</script>
